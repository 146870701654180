var exports = {};

/**
 * Copy a descriptor from one object to another.
 *
 * ```js
 * function App() {
 *   this.cache = {};
 * }
 * App.prototype.set = function(key, val) {
 *   this.cache[key] = val;
 *   return this;
 * };
 * Object.defineProperty(App.prototype, 'count', {
 *   get: function() {
 *     return Object.keys(this.cache).length;
 *   }
 * });
 *
 * copy(App.prototype, 'count', 'len');
 *
 * // create an instance
 * var app = new App();
 *
 * app.set('a', true);
 * app.set('b', true);
 * app.set('c', true);
 *
 * console.log(app.count);
 * //=> 3
 * console.log(app.len);
 * //=> 3
 * ```
 * @name copy
 * @param {Object} `receiver` The target object
 * @param {Object} `provider` The provider object
 * @param {String} `from` The key to copy on provider.
 * @param {String} `to` Optionally specify a new key name to use.
 * @return {Object}
 * @api public
 */
exports = function copyDescriptor(receiver, provider, from, to) {
  if (!isObject(provider) && typeof provider !== "function") {
    to = from;
    from = provider;
    provider = receiver;
  }

  if (!isObject(receiver) && typeof receiver !== "function") {
    throw new TypeError("expected the first argument to be an object");
  }

  if (!isObject(provider) && typeof provider !== "function") {
    throw new TypeError("expected provider to be an object");
  }

  if (typeof to !== "string") {
    to = from;
  }

  if (typeof from !== "string") {
    throw new TypeError("expected key to be a string");
  }

  if (!(from in provider)) {
    throw new Error("property \"" + from + "\" does not exist");
  }

  var val = Object.getOwnPropertyDescriptor(provider, from);
  if (val) Object.defineProperty(receiver, to, val);
};

function isObject(val) {
  return {}.toString.call(val) === "[object Object]";
}

export default exports;